import http from '@/utils/request'

/**
 * P2玩家游戏数据
 * @returns
 */
export const playerData = () => {
  return http.get('/game-data/p2-player-data')
}

/**
 * C5玩家游戏数据
 * @returns
 */
export const playerC5Data = () => {
  return http.get('/game-data/c5-player-data')
}

/**
 * 游戏列表
 * @returns
 */
export const gameList = () => {
  return http.get('/game-data/game-list')
}
