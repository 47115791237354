
export const formatDuration = (start,end) => {
  // 计算时间差（毫秒）
  const durationMs = (end - start)*1000;

  // 将时间差转换成分钟和秒数
  const minutes = Math.floor(durationMs / 60000);
  const seconds = Math.floor((durationMs % 60000) / 1000);

  // 返回格式化的字符串
  return `${minutes}分钟${seconds}秒`;
}

export const timestampToDateFormat = (timestamp) =>{

  const date = new Date(timestamp*1000);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${month}-${day} ${hours}:${minutes}`;
}
